<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Customers
            <v-spacer></v-spacer>
            <add-button permission="section-create" @action="form.dialog = true"
              >New Customer
            </add-button>
          </v-card-title>

          <v-card outlined>
            <v-card flat>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="dataSearch"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>

            <v-data-table
              :headers="headers"
              :items="form.items.data"
              :footer-props="footerProps"
              :option.sync="pagination"
              :server-items-length="form.items.meta.total"
              class="elevation-1"
            >
              <!-- <template
              v-slot:item="{ index, item }"
              style="display: flex; justify-content: space-between">
              <tr
                class="customer-data-table"
                style="display: flex; justify-content: space-between !important">
                <td class="text-xs-left">{{ ++index }}</td>
                <td class="text-xs-left">
                  {{ item.name.capitalizeFirstLetter() }}
                </td>
                <td class="text-xs-left">
                  <small>
                    {{ item.tel_no }}
                    <p small>
                      {{ item.primary_mobile }}
                      <span>{{ item.secondary_mobile }}</span>
                    </p>
                  </small>
                </td>
                <td class="text-xs-left">
                  <small>
                    {{ item.email }}
                    <p v-if="item.fax">Fax: {{ item.fax }}</p>
                  </small>
                </td>
                <td class="text-xs-left">
                  {{ item.address.capitalizeFirstLetter() }}
                </td>
                <td class="text-xs-left">
                  {{ item.pan_vat_no }}
                </td>
                <td class="text-xs-left">
                  <small>
                    <span v-if="item.bank_detail">
                      {{
                        item.bank_detail.name
                          ? item.bank_detail.name.capitalizeFirstLetter()
                          : ""
                      }}
                      <p>
                        {{
                          item.bank_detail.account_no
                            ? "(" + item.bank_detail.account_no + ")"
                            : ""
                        }}
                      </p>
                    </span>
                  </small>
                </td>
                <td class="justify-right text-xs-right">
                  <edit-button
                    permission="subject-read"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="subject-read"
                    @agree="form.delete(item.id)"
                  />
                  <v-icon small class="mr-2" color="primary" @click="editItem(props.item)">edit</v-icon>
                  <v-icon small color="error" @click="form.confirm(props.item)"> delete</v-icon>
                </td>
              </tr>
        </template> -->
            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title
          class="title primary white--text"
          style="margin-bottom: 20px"
          >Customer Information</v-card-title
        >
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-form
                  ref="form"
                  @submit.prevent="store"
                  @keydown.native="form.errors.clear($event.target.name)"
                  @keyup.enter="store"
                  lazy-validation
                >
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.name"
                        name="name"
                        label="Name"
                        :error-messages="form.errors.get('name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.tel_no"
                        name="tel_no"
                        label="Telephone Number"
                        :error-messages="form.errors.get('tel_no')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.primary_mobile"
                        name="primary_mobile"
                        label="Primary Mobile Number"
                        :error-messages="form.errors.get('primary_mobile')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.secondary_mobile"
                        name="secondary_mobile"
                        label="Secondary Mobile Number"
                        :error-messages="form.errors.get('secondary_mobile')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.email"
                        name="email"
                        label="Email"
                        :error-messages="form.errors.get('email')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.address"
                        name="address"
                        label="Address"
                        :error-messages="form.errors.get('address')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.fax"
                        name="fax"
                        label="Fax"
                        :error-messages="form.errors.get('fax')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>

                    <v-flex xs6>
                      <v-select
                        outlined
                        dense
                        v-model="form.pan_vat"
                        name="pan_vat"
                        :items="panVat"
                        label="Select PAN/VAT"
                        :height="25"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.pan_vat_no"
                        name="pan_vat_no"
                        :label="
                          form.pan_vat === 'V' ? 'VAT Number ' : 'PAN Number'
                        "
                        :error-messages="form.errors.get('pan_vat_no')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.credit_days"
                        name="credit_days"
                        label="Credit Days"
                        :error-messages="form.errors.get('credit_days')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.bank_detail.name"
                        name="bank_name"
                        label="Bank Name"
                        :error-messages="form.errors.get('bank_name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outlined
                        dense
                        v-model="form.bank_detail.account_no"
                        name="account_no"
                        label="Bank Account No"
                        :error-messages="form.errors.get('account_no')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions style="padding-bottom: 20px">
          <v-spacer />
          <v-btn color="error" text @click="form.dialog = false" outlined
            >Cancel</v-btn
          >
          <v-btn color="warning" text @click="save(true)" outlined
            >Save And Continue</v-btn
          >
          <v-btn color="success" text @click="save(false)" outlined>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="duplicateDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">Duplicate Data information</v-card-title>
        <v-card-text>Name: Dil Krishna Pila</v-card-text>
        <v-card-text>Primary Mobile: 9851286513</v-card-text>
        <v-card-text>Seconday Mobile: 9851286513</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="duplicateDialog = false"
            >Disagree</v-btn
          >
          <v-btn color="green darken-1" text @click="duplicateDialog = false"
            >Agree</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
        tel_no: '',
        primary_mobile: '',
        secondary_mobile: '',
        email: '',
        fax: '',
        address: '',
        pan_vat: '',
        pan_vat_no: '',
        credit_days: '',
        bank_detail: {
          name: 'Mega',
          account_no: '202020',
        },
      },
      '/api/customer'
    ),

    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    duplicateData: {},
    duplicateDialog: false,
    dataSearch: '',
    headers: [
      {
        text: '#',
        align: 'left',
        sortable: false,
        value: 'id',
      },
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Contact Number', value: 'tel_no', align: 'left' },
      // {text: 'Mobile', value: 'Mobile', align: "left"},
      { text: 'Email', value: 'email', align: 'left' },
      // {text: 'Fax', value: 'fax', align: "left"},
      { text: 'Address', value: 'address', align: 'left' },
      { text: 'PAN/VAT', value: 'pan_vat_no', align: 'left' },
      { text: 'Bank/Account', value: 'bank', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'right' },
    ],
    panVat: [
      { value: 'P', text: 'PAN' },
      { value: 'V', text: 'VAT' },
    ],
    valid: true,
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
  }),
  mounted: function () {
    this.get();
  },

  computed: {
    ...mapState(['batch']),
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    dataSearch: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
      // this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.dataSearch
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },

    save(tag) {
      this.form.closeModal = !tag;
      this.form
        .store()
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          this.duplicate = e.data.errors;
          console.log(e.data.errors);
          // console.log(e.respond)
        });
    },
  },
};
</script>
<style lang="scss">
.elevation-1 {
  // background-color: red !important;
  // display: flex;
  // justify-content: space-between;
}

.customer-data-table {
  // background: red;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text-xs-left {
  width: 100%;
  // background: lightblue;
}
</style>
